<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Telegram Account Details</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }" >
                        </CDataTable>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="primary" @click="redirect_to(`/telegram/account/${$route.params.id}/update`)" class="mr-2">Update</CButton>
                        <CButton color="dark" @click="go_back">Back</CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
export default {
    data () {
        return {
            items: [],
        }
    },
    computed: {
        fields(){
            return [
                { key: 'key', label: ''},
                { key: 'value', label: ''}
            ]
        }
    },
    mounted(){
        this.get();
    },
    methods: {
        get() {
            this.axios.get('system/telegram_accounts/'
                + '?id=' + this.$route.params.id
                + '&user_info=1')
                .then(res => {
                    res.data.map(val => {
                        switch(val.is_active){
                            case true:
                                val.is_active = 'Active';
                                break;
                            case false:
                                val.is_active = 'Inactive';
                                break;
                        }
                        if(val.last_activity != '0001-01-01T00:00:00Z'){
                            val.last_activity = this.$moment(val.last_activity).format('YYYY-MM-DD HH:mm:ss');
                        }
                        val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
                        this.items.push(
                            { key: 'ID', value: val.id },
                            { key: 'Telegram ID', value: val.telegram_id },
                            { key: 'Fitst Name', value: val.account_first_name },
                            { key: 'Last Name', value: val.account_last_name },
                            { key: 'User', value: `${val.user_info.first_name} ${val.user_info.last_name}`},
                            { key: 'State', value: val.is_active },
                            { key: 'Current Language', value: val.current_lang },
                            { key: 'Created at', value: val.created_at },
                            { key: 'Last Activiry', value: val.last_activity },
                            { key: 'Last Request', value: val.last_application },
                        );
                    })
                })
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        go_back(){
            this.$router.go(-1);
        }
    },
}
</script>
